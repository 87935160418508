<template>
  <div class="page">
    <BreadCrumb />
    <el-button class="goBack" plain round size="mini" type="primary" @click="goBack"> 返回 </el-button>
    <div class="page-body log">
      <div v-loading="loading" class="cont">
        <el-scrollbar height="550px">
          <highlightjs language="auto" :code="viewLog" />
        </el-scrollbar>
      </div>
      <div class="text-center">
        <el-button size="small" type="primary" @click="moreLog"> 更多 </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑
export default {
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
    return {
      workId: '', //返回用
      projectId: '', //返回用
      loading: true,
      taskId: '',
      viewLog: '',
      pageP1: '',
      pageP2: '',
      pageP3: '',
    }
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
    this.taskId = Number(this.$route.query.taskId)
    this.workId = Number(this.$route.query.workId)
    this.projectId = Number(this.$route.query.projectId)
    this.pageP1 = Number(this.$route.query.pageP1)
    this.pageP2 = Number(this.$route.query.pageP2)
    this.pageP3 = Number(this.$route.query.pageP3)
    this.getLog()
  },
  methods: {
    //获取日志信息
    getLog() {
      var _this = this
      _this.loading = true
      let datas = {
        taskId: this.taskId,
      }
      this.$axios.POST('/out/api/v1/core/log', datas).then(function (res) {
        let data = res.message
        _this.viewLog = data
        _this.loading = false
      })
    },
    //返回上一级
    goBack() {
      //带参数跳转
      this.$router.push({
        name: 'workTask',
        query: {
          workId: this.workId,
          projectId: this.projectId,
          pageP1: this.pageP1,
          pageP2: this.pageP2,
          pageP3: this.pageP3,
        },
      })
    },
    moreLog() {
      this.getLog()
    },
  },
}
</script>

<style scoped>
.log .cont {
  border: 1px solid #e3e8ee;
  padding: 10px;
  height: calc(100vh - 199px);
  margin-bottom: 15px;
  overflow: auto;
}
</style>
